import React from 'react';
import gql from 'graphql-tag';
import { withApollo } from 'react-apollo';
import cn from 'classnames';

import './App.css';
import queryString from 'query-string';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Warning from '@material-ui/icons/Warning'

const styles = {
  header: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    textAlign: 'center',
    zIndex: 1,
    backgroundColor: 'white',
    padding: 7,
    boxShadow: '0 0 5px rgba(100, 100, 100, 0.5)'
  },
  goToShop: {
    position: 'absolute',
    bottom: 0,
    textAlign: 'center',
    width: '100%',
    left: 0,
    marginBottom: 15,
    zIndex: 2,
  },
  fullUi: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 10,
    boxSizing: 'border-box',
  },
  loader: {
    marginBottom: 10
  },
  start: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 20,
    height: 40,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    padding: '0 30px',
    fontSize: '20px',
    marginTop: 15
  },
  errorIcon: {
    fontSize: '5em',
    marginBottom: 10,
    color: '#ffb46c'
  },
  error: {
    textAlign: 'center'
  }
};

class App extends React.Component {

  state = {
    ar: false,
    error: null,
    loading: true,
    loadingState: 'CHECK_ENV', // l'appliaction a plusieurs etats, chargement, erreur etc....
    showInstructions: false
  };

  params = null;

  componentDidMount() {
    // On regarde si le navigateur est compatible avec l'AR
    // sinon on affiche une info
    if (navigator.mediaDevices === undefined
      || navigator.mediaDevices.enumerateDevices === undefined
      || navigator.mediaDevices.getUserMedia === undefined  )
    {
      this.setState({
        error: 'BROWSER_NOT_COMPATIBLE'
      });
      return;
    }


    // on regarde si il y a bien les parametres necessaire dans l'url
    // params: id & token (id --> id_ar_scene)
    // sinon on affiche une erreur
    const params = queryString.parse(window.location.search);
    if (!params.id || !params.token) {
      this.setState({
        error: 'MISSING_PARAMS'
      });
      return;
    }


    this.setState({ loadingState: 'GET_MESSAGE' });

    // on recupere les infos du message
    this.props.client.query({
      query: gql`{
        getOrderMessage(id_order_message: ${params.id}, token: "${params.token}") {
          message
          ar_scene {
            assets_html
            env_html
            scene_html
            text_size
            text_position
            text_rotation
            text_line_height
            text_geometry_properties
            text_material
            text_anchor
          }
        }
      }`
    }).then(({ data: { getOrderMessage } }) => {

      if (!getOrderMessage) {
        this.setState({ loading: false, error: 'MESSAGE_NOT_FOUND' });
      } else {
        this.params ={
          message: getOrderMessage.message,
          arScene: getOrderMessage.ar_scene
        };
        this.setState({ loading: false, showInstructions: true });
      }

    }).catch(e => {
      this.setState({ loading: false, error: 'MESSAGE_NOT_FOUND' });
    });
  }

  renderTexts = () => {
    let ret = '';

    // chaque ligne doit etre un objet text different
    const lines = this.params.message.replace(/^\s*[\r\n]/gm, '').split('\n');

    lines.forEach((line, i ) => {
      // il y a trois anchrage possible
      // et donc une position en y different pour chaque
      let y = 0;
      switch (this.params.arScene.text_anchor) {
        case 'MIDDLE':
          y = ((((lines.length / 2) - i - 1) * this.params.arScene.text_size) * this.params.arScene.text_line_height);
          break;
        case 'BOTTOM':
          y = ((((lines.length) - i - 1) * this.params.arScene.text_size) * this.params.arScene.text_line_height);
          break;
        case 'TOP':
          y = ((- i * this.params.arScene.text_size) * this.params.arScene.text_line_height);
          break;
        default:
          y = 0;
          break;
      }
      ret +=`<a-entity
                class="3d-text"
                position="0 ${y} 0"
                text-geometry="value: ${line}; size: ${this.params.arScene.text_size}; ${this.params.arScene.text_geometry_properties}"
                material="${this.params.arScene.text_material}"
              ></a-entity>`;
    });
    return ret;
  };

  startAR = () => {

    // aframe a besoin d'etre au niveau du body, du coup on utilise pas react
    // ici mais du vanilla js pour le render

    if (!this.params) {
      return;
    }

    this.setState({ loading: true, showInstructions: false, loadingState: 'LOADING_SCENE'  });

    // aframe, initialisation de la scene avec les different params
    const html = `<a-scene
            loading-screen="dotsColor: #bbb; backgroundColor: #f5f5f5"
            vr-mode-ui="false"
            isMobile="true"
            shadow="type: pcfsoft; autoUpdate: true; enabled: false;"
            embedded
            arjs="sourceType: webcam; debugUIEnabled: false;"
            renderer="logarithmicDepthBuffer: true; colorManagement: true;"
            gltf-model="dracoDecoderPath: https://cdn.monsieurtshirt.com/ar/draco/;"
          >
           <a-assets>
             ${this.params.arScene.assets_html}
            </a-assets>
            <a-marker type="pattern" url='https://cdn.monsieurtshirt.com/ar/assets/pattern-m-ar.patt' preset="custom">
              ${this.params.arScene.scene_html} 
               <a-entity
                  shadow="receive: true; cast: true;"
                  id="text"
                  position="${this.params.arScene.text_position}"
                  rotation="${this.params.arScene.text_rotation}"
                >
                  ${ this.renderTexts() }
                </a-entity>
            </a-marker>
            <!--<a-entity camera position="0 0 2.5" rotation="0 0 0"></a-entity>-->
            <a-entity camera="near: 0.0001;"></a-entity>
            ${this.params.arScene.env_html}
      </a-scene>`;

    //console.log('html: ', html);

    document.querySelector('body').insertAdjacentHTML( 'beforeend', html);

    // une fois la scene initiliser on appele la fonction sceneLoaded
    const scene = document.querySelector('a-scene');
    if (scene.hasLoaded) {
      this.sceneLoaded();
    } else {
      scene.addEventListener('loaded', this.sceneLoaded);
    }
  };

  sceneLoaded = () => {
    console.log('scene loaded');
    // une fois que la scene est bien initaliser on attend
    // que les assets le soit aussi et on appel la fonction assetsLoaded
    const assets = document.querySelector('a-assets');
    if (assets.hasLoaded) {
      this.assetsLoaded();
    } else {
      assets.addEventListener('loaded', this.assetsLoaded);
    }
  };

  assetsLoaded = () => {
    // les assets sont bien initilisé (present sur la scene)
    // c'est important pour pour les textes on a besoin des information de dimension disponible comme pour du html
    // seulement quand les elements sont visible
    this.resizeTexts();
    this.removeFrustumCulled();
  };


  // permet de retiré l'occlusion (disparision) des models si non visible pour la camera
  // en soit c'est bien pour les perfs du large scene, pour nous c'est pas genant.
  // On pourrait garder la default seulement avec AR.js et la vue camera il y a des petits problem ou des partie du
  // models disparaissent alors que ca ne devrait pas.
  removeFrustumCulled = () => {
    const items = document.querySelectorAll('a-entity[gltf-model]');
    const promises = [];

    for (let i = 0 ; i < items.length ; i++) {
      promises.push(new Promise((resolve, reject) => {
        items[i].addEventListener('model-loaded', () => {
          resolve();
        });
      }));
    }

    Promise.all(promises).then(() => {

      for (let i = 0 ; i < items.length ; i++) {
        const mesh = items[i].getObject3D('mesh');
        this.disableFrustumCulled(mesh);
      }
    });
  };

  disableFrustumCulled = (mesh) => {
    mesh.frustumCulled = false;
    if (mesh.children && mesh.children.length > 0) {
      for (let i = 0 ; i < mesh.children.length ; i++) {
        this.disableFrustumCulled(mesh.children[i]);
      }
    }
  };

  // Etant donner qu'il n'y a pas de possibilité via aframe de center plusieurs ligne de textes
  // facilement, on utilise cette methode
  // on recuperer les information des tailles (largeur) de chaque ligne de texte
  // et avec la ligne la plus grand et la taille du texte on peut trouver la bonne possition en x necessaire
  // pour que tout les textes soient centrés.
  resizeTexts = () => {
    const items = document.getElementsByClassName('3d-text');

    const promises = [];
    // is fuat attendre que les object threejs text soit bien init
    for (let i = 0 ; i < items.length ; i++) {
      if (!items[i].hasLoaded) {
        promises.push(new Promise((resolve, reject) => {
          items[i].addEventListener('loaded', () => {
            resolve();
          });
        }));
      }
    }

    Promise.all(promises).then(() => {

      let maxWidth = 0;
      for (let i = 0 ; i < items.length ; i++) {
        const mesh = items[i].getObject3D('mesh');
        if (mesh) {
          const bbox = new window.THREE.Box3().setFromObject(mesh);
          const width = bbox.max.x;
          if (maxWidth < width) {
            maxWidth = width;
          }
        }
      }
      for (let i = 0 ; i < items.length ; i++) {
        const mesh = items[i].getObject3D('mesh');
        if (mesh) {
          const bbox = new window.THREE.Box3().setFromObject(mesh);
          const width = bbox.max.x;
          // centrer
          items[i].object3D.position.x = ((maxWidth - width) / 2) - (maxWidth / 2);
        }
      }

      this.setState({ loading: false, ar: true });
    });
  };

  renderErrorMessage = () => {
    switch (this.state.error) {
      case 'MISSING_PARAMS':
        return <React.Fragment>Paramètres manquants.<br />Impossible de charger le message.</React.Fragment>;
      case 'BROWSER_NOT_COMPATIBLE':
        return <React.Fragment>Votre navigateur n'est pas compatible avec cette application.<br/><br/>Pour iOS (iPhone, iPad), essayez avec Safari.<br/>Pour Android, essayez avec Chrome.</React.Fragment>;
      case 'MESSAGE_NOT_FOUND':
        return <React.Fragment>Impossible de charger le message.<br/>Veuillez vérifier l'URL saisie.</React.Fragment>;
      default:
        return <React.Fragment>Erreur inconnue.</React.Fragment>;
    }
  };

  renderLoadingMessage = () => {
    switch (this.state.loadingState) {
      case 'CHECK_ENV':
        return 'Vérification de l\'environnement...';
      case 'GET_MESSAGE':
        return 'Récupération du message...';
      case 'LOADING_SCENE':
        return 'Initialisation du message...';
      default:
        return 'Chargement en cours....';
    }
  };

  renderShopLink = () => {
    const { classes } = this.props;
    return <div className={classes.goToShop}>
      <Button variant="contained" color="primary" href="https://www.monsieurtshirt.com">
        Voir la boutique
      </Button>
    </div>
  };

  renderInner = () => {
    const { error, loading, showInstructions } = this.state;
    const { classes } = this.props;

    // Affichage d'erreure
    if (error) {
      return <div className={classes.error}>
        <div className={classes.fullUi}>
          <Warning className={classes.errorIcon}/>
          <Typography variant="h5" gutterBottom>
            { this.renderErrorMessage() }
          </Typography>
        </div>
        { this.renderShopLink() }
      </div>;
    }

    // Chargement
    if (loading) {
      return <div className={classes.fullUi}>
        <div>
          <CircularProgress color="secondary" className={classes.loader}/>
        </div>
        <div>
          <Typography variant="body1" gutterBottom>
            { this.renderLoadingMessage() }
          </Typography>
        </div>
      </div>
    }

    // Vue initiale
    if (showInstructions) {
      return <div className={classes.fullUi}>
        <img src="https://cdn.monsieurtshirt.com/ar/assets/ar.png" width={130} alt="Logo AR"/>
        <ul>
          <li><Typography variant="body1" gutterBottom>Pose ton message sur une surface plane. Dans un lieu bien éclairé 💡 pour un meilleur résultat !</Typography></li>
          <li><Typography variant="body1" gutterBottom>Appuye sur le bouton <strong>"Démarrer"</strong>.</Typography></li>
          <li><Typography variant="body1" gutterBottom>Autorise l'accès à ton appareil photo 📷.</Typography></li>
          <li><Typography variant="body1" gutterBottom>Vise le marqueur <img src="https://cdn.monsieurtshirt.com/ar/assets/marker-icon.jpg" alt="M" width={20}/> du message.</Typography></li>
          <li><Typography variant="body1" gutterBottom>Enjoy ! 😎️</Typography></li>
        </ul>
        <Button className={classes.start} variant="contained" onClick={this.startAR}>
          ⚡ Démarrer ⚡
        </Button>
      </div>
    }
    return this.renderShopLink();
  };


  render () {

    const {classes} = this.props;

    return <div>
      <div className={classes.header}>
        <a href="https://www.monsieurtshirt.com" alt="Monsieur TSHIRT">
          <img src="https://cdn.monsieurtshirt.com/assets/logos/Logo-MR-Bleu.png" alt="Logo" width="120"/>
        </a>
      </div>
      { !this.state.ar && <div className="background">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div> }
      { this.renderInner() }
    </div>;
  }
}

export default withApollo(withStyles(styles)(App));
