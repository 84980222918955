import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { ApolloProvider } from 'react-apollo';

import './index.css';
import 'normalize.css';

import App from './App';

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    new HttpLink({
      uri: 'https://api.monsieurtshirt.com/graphql',
      credentials: 'include',
      headers: {
        'no-session': 'true'
      }
    })
  ]),
  cache: new InMemoryCache()
});

const MyApp = () => ( <ApolloProvider client={client}><App /></ApolloProvider>);

ReactDOM.render(<MyApp />, document.getElementById('root'));
